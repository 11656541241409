﻿@import "./variables";

/* 
 * Menu
 */
.side-navigation{

    .main-sidebar {
        display: flex;
        flex-direction: column;
        background-color: $background-color;
        position: fixed;
        top: 0;
        left: 0;
        min-height: 100%;
        height: 100%;
        width: $sidebar-width;
        z-index: 1000;
        -webkit-transition: -webkit-transform .3s ease-in-out, width .3s ease-in-out;
        -moz-transition: -moz-transform .3s ease-in-out, width .3s ease-in-out;
        -o-transition: -o-transform .3s ease-in-out, width .3s ease-in-out;
        transition: transform .3s ease-in-out, width .3s ease-in-out;

        input{
            background-color: rgba(255, 255, 255, 0.2);
            color: white;
        }
        input::-webkit-input-placeholder,
        input::-moz-placeholder,
        input::-ms-input-placeholder{
            color: white;
        }

        .sidebar-nav{
            margin-bottom: auto;
        }

        .sidebar__logo {
            display: flex;
            justify-content: center;
            padding: 1em;
            width: $sidebar-width;
            opacity: 0.25;
            
            &[hidden] {
                display: none;    
            }
        }

        .main-logo{
            padding: 0.3em 0em;
        }

    }

    .content-wrapper {
        margin-top: 1em;
        -webkit-transition: -webkit-transform .3s ease-in-out, margin .3s ease-in-out;
        -moz-transition: -moz-transform .3s ease-in-out, margin .3s ease-in-out;
        -o-transition: -o-transform .3s ease-in-out, margin .3s ease-in-out;
        transition: transform .3s ease-in-out, margin .3s ease-in-out;
        margin-left: $sidebar-width;
        padding: .75em 1.5em;
        z-index: 820;
    }

    .control-sidebar-bg,
    .control-sidebar {
        z-index: 49;
        position: fixed;
        background-color: #EEE;
        border-left: 1px solid lightgray;
        padding: 3.5em 0.5em 0 0.5em;
        height: 100%;
        top: 0;
        right: -260px;
        width: $sidebar-width;
        -webkit-transition: right .3s ease-in-out;
        -o-transition: right .3s ease-in-out;
        transition: right .3s ease-in-out;
    }

    .control-sidebar.control-sidebar-open,
    .control-sidebar.control-sidebar-open + .control-sidebar-bg {
        right: 0;
        overflow-y: auto;
    }

    .sidebar-nav a.active {
        background-color: #1b2428;
        color: white !important;
        font-weight: bold;
        
        .badge {
            display: none;
        }
    }

}


@media (max-width: 544px) {
    .navbar.navbar-fixed-top .menu-wrapper {
        padding-left: 0;
    }

    #toggle-menu{
        z-index: 1000;
    }

    .side-navigation .main-sidebar {
        display: none;
        margin-top: 3.5em;

        -webkit-transition: right .3s ease-in-out;
        -o-transition: right .3s ease-in-out;
        transition: right .3s ease-in-out;
    }

    .side-navigation .content-wrapper {
        margin-left: 0;
    }

    /* Sidebar */
    aside {
        display: block;
    }

    .navbar.navbar-fixed-top {
        background-color: #222d32;
        color: #c2c2c2;
        z-index: 50;
        height: 3em;
        -webkit-transition: margin-left .3s ease-in-out;
        -o-transition: margin-left .3s ease-in-out;
        transition: margin-left .3s ease-in-out;
        margin-bottom: 0;
        border: none;
        min-height: 50px;
        border-radius: 0;
    }
}


/*  SIDEBAR */

.sidebar {
    /*background: #203440;*/
    padding: 0;
    height: 100%;
}

.sidebar-nav {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    /*background-image: -webkit-linear-gradient(left, color-stop(#192c38 10px), color-stop(#20343f 10px));*/
    /*background-image: linear-gradient(to right, #192c38 5px, #20343f 10px);*/
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff333333', endColorstr='#ff222222', GradientType=1);
}

.sidebar-nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav a, .sidebar-nav a:hover, .sidebar-nav a:focus, .sidebar-nav a:active {
    outline: none;
}

.sidebar-nav ul li, .sidebar-nav ul a {
    display: block;
}

.sidebar-nav ul a {
    padding: 0.75em 1em;
    color: #c2c2c2;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.05) inset;
}

.sidebar-nav ul a:hover, .sidebar-nav ul a:focus, .sidebar-nav ul a:active {
    color: #fff;
    text-decoration: none;
}

.sidebar-nav ul ul a {
    padding: 10px 30px;
    background-color: rgba(255, 255, 255, 0.1);
}

.sidebar-nav ul ul ul a {
    padding: 10px 40px;
    background-color: rgba(255, 255, 255, 0.2);
}

.sidebar-nav ul ul a:hover, .sidebar-nav ul ul a:focus, .sidebar-nav ul ul a:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.sidebar-nav-item {
    padding-left: 5px;
}

.sidebar-nav-item-icon {
    padding-right: 5px;
}

.sidebar {
    /*background: #203440;*/
    padding: 0;
    height: 100%;
}

.sidebar-nav-light {
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff333333', endColorstr='#ff222222', GradientType=1);

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    a, a:hover, a:focus, a:active {
        outline: none;
    }

    ul li, ul a {
        display: block;
    }
}

.sidebar-nav-light ul li:hover {
    background-color: #EEE;
    color: black;
}

.sidebar-nav-light ul a {
    padding: 0.75em 1em;
    color: #222d32;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar-nav-light ul a:hover, .sidebar-nav-light ul a:focus, .sidebar-nav-light ul a:active {
    text-decoration: none;
    font-weight: bold;
}

.sidebar-nav-light ul ul a {
    padding: 10px 30px;
    background-color: rgba(255, 255, 255, 0.1);
}

.sidebar-nav-light ul ul ul a {
    padding: 10px 40px;
    background-color: rgba(255, 255, 255, 0.2);
}

.sidebar-nav-light ul ul a:hover, .sidebar-nav-light ul ul a:focus, .sidebar-nav-light ul ul a:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.sidebar-nav-item {
    padding-left: 5px;
}

.sidebar-nav-item-icon {
    padding-right: 5px;
}



/* Top Menu */
aside {
    display: block;
}

.navbar.navbar-fixed-top {
    background-color: $background-color--light;
    z-index: 50;
    height: 3em;
    -webkit-transition: margin-left .3s ease-in-out;
    -o-transition: margin-left .3s ease-in-out;
    transition: margin-left .3s ease-in-out;
    margin-bottom: 0;
    border: none;
    min-height: 50px;
    border-radius: 0;
}

.navbar.navbar-fixed-top .menu-wrapper {
    display: inline-block;
    padding-left: $sidebar-width;
    width: 100%;
    height: 100%;
}

.navbar.navbar-fixed-top .menu-wrapper > .menu-container {
    padding: 0.25em 1em;
    height: 100%;
    font-weight: 600;
    font-family: $title-font;
}

.navbar.navbar-fixed-top .menu-wrapper > .menu-container div {
    height: 100%;
}

.logo-wrapper {
    padding: 1em 0.5em;
}

.menu-btn {
    background-color: transparent;
    color: inherit;
    height: 3em;
    margin: 0;
    padding: 0 1em;
}

.menu-btn i {
    font-weight: bold;
}

.menu-btn div {
    margin-top: 8px;
}

.menu-btn div {
    margin-top: 8px;
}

.menu-btn:hover {
    color: $background-color;
}

.menu-btn:focus {
    outline: none;
    color: $background-color;
}



@media (max-width: 544px) {
    .navbar.navbar-fixed-top .menu-wrapper {
        padding-left: 0;
    }

    #toggle-menu{
        z-index: 1000;
    }

    .side-navigation .main-sidebar {
        display: none;
        margin-top: 3.5em;

        -webkit-transition: right .3s ease-in-out;
        -o-transition: right .3s ease-in-out;
        transition: right .3s ease-in-out;
    }

    .side-navigation .content-wrapper {
        margin-left: 0;
    }

    /* Sidebar */
    aside {
        display: block;
    }

    .navbar.navbar-fixed-top {
        background-color: $background-color;
        color: #c2c2c2;
        z-index: 50;
        height: 3em;
        -webkit-transition: margin-left .3s ease-in-out;
        -o-transition: margin-left .3s ease-in-out;
        transition: margin-left .3s ease-in-out;
        margin-bottom: 0;
        border: none;
        min-height: 50px;
        border-radius: 0;
    }
}


/******************************************* Menu *******************************************/
/* Navigation Menu */
.main-navigation {
    text-align: right;
    z-index: 100;

    a {
       color: #676767;
    }
}


.main-navigation .menu-btn {
    border-left: 1px solid #CCC;
}

.main-navigation .menu-btn:last-child {
    border-right: 1px solid #CCC;
}

.main-navigation ul {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;

    li:first-of-type {
        flex: 1;
        margin: 0 0.5em;
    }

}

.main-navigation .search {
    position: absolute;
    top: 5px;
    right: 530px;
    width: 200px !important;
}

.menu-main-container {
    margin-top: 10px;
    line-height: 60px;
}

.sub-menu li {
    display: block;
    padding: 10px;
    margin: 0;
    white-space: nowrap;
    text-align: left;
}

.main-navigation li:hover > .sub-menu {
    max-height: 800px;
    overflow: hidden;
}

.sub-menu .sub-menu {
    position: relative;
    z-index: 0;
    left: 0;
    top: 2px;
}

.badge-danger {
    background-color: #d43f3a;
}

.badge-warning {
    background-color: #d58512;
}

.badge-success {
    background-color: #398439;
}

.badge-info {
    background-color: #269abc;
}

.badge-inverse {
    background-color: #333333;
}



