﻿@import "./variables";

/* NAVBAR */
.navbar > .container {
    padding: 0.5em 0;
}

/* SIDEBAR */

@media (min-width: 767px) {
    .affix, .affix-top {
        position: fixed;
    }
}

/* IMG */
.img-responsive {
    width: 95%;
    display: inline;
}

@media (min-width: 1400px) {
    .img-responsive {
        width: 85%;
    }
}

/* FORMS */
.input-group-addon{
    background-color: white;
    border: none;
    min-width: 40px;
    border-radius: 0;
}

input[readonly],
select[readonly]{
    pointer-events: none;
}

.control-label.control-label--left {
    text-align: left;
}

.form-control:focus {
    border-color: #0a8f08;
    box-shadow: none;
}

.form-group {
    margin-bottom: 0.5em;
}

.form-control {
    font-size: 1em;
    padding: 0.1em 0.5em;
    border-radius: 0;
    background-color: white;
    border: 1px solid #e0e0e0;

    &.mw-100{
        min-width: 100px;
    }

    &.--lg{
        min-width: 50px;
        height: 50px;
    }
}



.form-control.lg-input {
    font-size: 1em;
    height: 34px;
}

/* PANEL */

.panel {
    border: none;
    margin-bottom: 1em;
}

.panel-heading {
    color: #43B54A;
    font-weight: bold;
    background-color: $primary-color;
    border-color: #E5E5E5;
}

.panel a {
    color: #43B54A;
    text-decoration: none;
}

.panel-heading,
.btn {
    border-radius: 0;
}

.btn {

    &.--lg{
        font-size: 1.3em;
        padding: 0.8em 1em;
        font-family: $title-font;
    }
    
    &.btn-danger {
        background-color: transparent;
        color: #d9534f;
        border: none;
    }
}

.btn.btn-round {
    border-radius: 15px;
    padding: 0.15em 0.5em;
    font-weight: bold;
}


.panel-success {
    border-color: #43B54A;

    a {
        color: #fff;
        text-decoration: none;
    }

    & > .panel-heading a:hover,
    & > .panel-heading a:focus {
        color: #fff;
        text-decoration: underline;
    }
}

/*.panel-success > .panel-heading {
    color: #fff;
    background-color: #43B54A;
    border-color: #43B54A;
}

.panel-success > .panel-heading + .panel-collapse .panel-body {
    border-top-color: #43B54A;
}

.panel-success > .panel-footer + .panel-collapse .panel-body {
    border-bottom-color: #43B54A;
}*/

.panel-default > .panel-heading {
    background-color: #CCC;
    color: $body-color-light;
}

.panel > .panel-heading {
    padding: 0.3em 1em;
    border: none;
    font-family: $title-font;
}

.panel > .panel-body {
    padding: 0.75em 1em 0.5em 1em;
}

.panel:hover > .panel-heading {
    background-color: $primary-color--dark;
    color: white;
}

.panel:hover > .panel-heading a,
.panel:focus > .panel-heading a,
.panel.selected > .panel-heading a {
    color: white;
}

.panel:focus > .panel-heading {
    background-color: #82C185;
    color: white;
}

.panel.selected > .panel-heading {
    background-color: $primary-color;
    color: white;
}

input {
  border: 1px solid #e0e0e0;
}

.panel input,
.panel textarea,
.panel select,
.panel input[required],
.panel input:not([readonly])[required] {
    padding-left: 0.5em;
}

.panel #datepicker {
    width: 100% !important;
}

.panel .toggle-icon i.icon:before {
    content: "\f046";
}

.panel-enodo.summary .toggle-icon i.icon:before {
    content: "\f044";
}

.panel-enodo .summary .row {
    margin: 0;
}

.panel-enodo .summary .form-group {
    margin: 0 0 0.25em 0;
}

/* Modals */
.background-primary {
    background-color: #43b54a;
    color: white;
}

.modal-enodo {
    width: auto;
    transform: translate(-25%, -50%);
    position: absolute !important;
    left: 50%;
    top: 25% !important;
}

.modal-enodo .modal-dialog {
    margin: 0.5em auto;
}

.modal-enodo .modal-footer p {
    margin: 0;
}

.modal input,
.modal input[required],
.modal input:not([readonly])[required] {
    padding-left: 0.5em;
    //width:auto;
}

/* HELPERS */
.inline {
    display: inline-block;
}

p.text-small {
    font-size: 0.9em;
}

.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

tr.row-selected td {
    background-color: #dddddd;
}

.font-bold {
    font-weight: bold;
    font-size: 0.9em;
}

.active > a > .glyphicon.arrow:before,
a .glyphicon.arrow:before {
    font-size: 0.70em;
    content: "\e114";
}

.glyphicon.arrow:before,
a.collapsed .glyphicon.arrow:before {
    font-size: 0.70em;
    content: "\e079";
}

.arrow {
    float: right;
}

.btn-zero {
    border: none;
    background-color: inherit;
    color: #43b54a;
}

input.error, select.error, textarea.error {
    border: 1px solid red !important;
    color: #ad0000;
}

.caret {
    float: right;
}

/* TOOLTIP */
.tooltip-inner {
    max-width: 400px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background-color: #43B54A;
    border-radius: 10px;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #43B54A;
}

/* BUTTON */
button.default {
    border: none;
    background-color: inherit;
}

/* LAYOUT */
.clearfix {
    margin-bottom: 1em;
}

@media (min-width: 750px) {
    .vertical-align {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: initial !important;
    }
}

@media (min-width: 1600px) {
    .container {
        width: 1500px;
    }
}


/* TABLE */
/*.panel-enodo table.dataTable.summaryTable tbody tr td input{*/
/*border: none;*/
/*}*/

/* Icons */
a.icon {
    padding: 0.5em;
    color: #929497;
}

[class^="icon-"].icon-normal, [class*=" icon-"].icon-normal {
    font-weight: normal;
}

[class^="icon-"].icon-bold, [class*=" icon-"].icon-bold {
    font-weight: bold;
}

[class^="icon-"].icon-lg, [class*=" icon-"].icon-lg {
    font-size: 2em;
}

[class^="icon-"].icon-md, [class*=" icon-"].icon-md {
    font-size: 1.5em;
}

.background-primary [class^="icon-"], .background-primary [class*=" icon-"] {
    color: white;
}



.m-r {
    margin-right: 0.35em;
}

.full-width {
    width: 100%;
}

.dx-texteditor {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 0px;
}

.dx-texteditor-input {
    border-radius: 0;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: 1px solid #ddd;
    border-radius: 0px;
}

.is-round{
    border-radius: 1em !important;
}

.btn.btn-link {
    text-decoration: underline;
}