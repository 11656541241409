﻿.progress {
    position: fixed;
    top: 3.8em;
    width: 100%;
    border-radius: 0;
    left: $sidebar-width;
    font-weight: bold;
    z-index: 49;

    .progress-bar {
        //background-color: #283237;
        //text-align: center !important;
        //margin-left: 20px;
        padding-right: 220px !important;

    }



}
