﻿
@import './variables';

/* GLOBALS */
html, body{
    min-height: 100%;
    height: 100%;
}

body {
    font-size: 13px;
    font-family: $main-font;
    font-weight: 400;
    background-color: #fafafa;
    padding-top: 3em;
}

h1, h2, h3, h4{
    font-family: Montserrat;
}

a {
    cursor: pointer;
    color: $primary-color;
    text-decoration: none;

}

label {
    font-weight: normal;
}

table {
    font-size: 13px;
}


h1, .h1 {
    font-size: 1.75em;
    margin: 0.5em 0 0.25em 0;
    font-weight: bold;
}

h2{
}

h3 {
    font-size: 1em;
    font-weight: bold;
    margin: 0 0 1em 0;
}

nav {
    color: $body-color-light;
}
