




























































































































.input-table-wrapper {
    width: 100%;
}

.input-table {
    width: 100%;
    display: -ms-grid;
    display: grid;
    grid-gap: 0;
    margin-bottom: 0.5em;

    > * {
        width: 100%;
        height: 100%;
        min-height: 30px;
        border: none;
        border-left: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        padding: 3px;
    }

    > .is-borderless {
        border: none;
    }

.is-readonly {
    background-color: #f2f2f2;
    pointer-events: none;
}

    > .input-table__header {
        font-weight: bold;
        align-self: center;
        font-size: 0.925em;
        padding: 5px;
        border: none;
        border-bottom: 2px solid #e0e0e0;
        border-left: none;
    }

    > div.input-table__input {
        margin: 0;
        padding: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.input-table__input--has-error,
        &.input-table__input--has-error {
            border: 1px solid #ce4844;
        }

        > input[type=checkbox],
        > input[type=radio] {

        }

        > *:not([type=checkbox]):not([type=radio]) {
            height: 100%;
            width: 100%;
            border: none;
        }
    }

    .input-table__input__remove {
        border: none;
        border-left: 1px solid #e0e0e0;
    }

    .form-group {
        padding: 0;
        margin: 0;
        height: 100%;

        > * {
            height: 100%;
        }
    }

    .vs__dropdown-toggle {
        height: 100%;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        border: none;
        padding: 0;
    }
}

.input-table__footer {
    margin-right: 30px;
    display: flex;
    justify-content: space-between;

    > * {
        /*flex: 1;*/
    }
}
