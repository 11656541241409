
















































































































































































































































.quick-quote__row {
    padding-right: 40px;
    margin-bottom: .5em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    &--end {
        margin-bottom: .5em;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.quick-quote__footer {
    margin-right: 40px;
    margin-bottom: .5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap-reverse;

    > * {
        margin: 0.5em 0;
    }

    .quick-quote__summary {
        margin-left: auto;
    }

    .quick-quote__actions {
        display: flex;
        justify-content: flex-end;
        flex: 1;

        .quick-quote__row__btn {
            margin: 0;
            max-width: 180px;
            display: inline-block;
        }
    }
}
