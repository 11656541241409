.td-ellipsis {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inline {
  display: flex;
}

.oneflo {
  &-loader {
    margin: 1em;
    width: 100%;
    opacity: .5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.title {
  font-size: 1.5em;
}