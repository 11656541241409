﻿@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');

@font-face {
    font-family: 'icomoon';
    src:    url('../../../Content/fonts/SendIT/icomoon.eot?1bqpgg');
    src:    url('../../../Content/fonts/SendIT/icomoon.eot?1bqpgg#iefix') format('embedded-opentype'),
        url('../../../Content/fonts/SendIT/icomoon.ttf?1bqpgg') format('truetype'),
        url('../../../Content/fonts/SendIT/icomoon.woff?1bqpgg') format('woff'),
        url('../../../Content/fonts/SendIT/icomoon.svg?1bqpgg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-size: 1.2em;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-icons-account-configurationlarge:before {
    content: "\e900";
}
.icon-icons-add-address:before {
    content: "\e902";
}
.icon-icons-add-item:before {
    content: "\e903";
}
.icon-icons-add-product:before {
    content: "\e904";
}
.icon-icons-add-user-field:before {
    content: "\e905";
}
.icon-icons-add-user:before {
    content: "\e906";
}
.icon-icons-add:before {
    content: "\e907";
}
.icon-icons-advanced-search:before {
    content: "\e908";
}
.icon-icons-archive:before {
    content: "\e909";
}
.icon-icons-box:before {
    content: "\e90a";
}
.icon-icons-cancel:before {
    content: "\e90b";
}
.icon-icons-connote:before {
    content: "\e90c";
}
.icon-icons-cost-center:before {
    content: "\e90d";
}
.icon-icons-cost:before {
    content: "\e90e";
}
.icon-icons-dangerous-goods:before {
    content: "\e90f";
}
.icon-icons-deactivate:before {
    content: "\e910";
}
.icon-icons-delete:before {
    content: "\e911";
}
.icon-icons-down-arrow:before {
    content: "\e912";
}
.icon-icons-download:before {
    content: "\e913";
}
.icon-icons-edit:before {
    content: "\e914";
}
.icon-icons-email:before {
    content: "\e915";
}
.icon-icons-enquiry:before {
    content: "\e916";
}
.icon-icons-export:before {
    content: "\e917";
}
.icon-icons-filter:before {
    content: "\e918";
}
.icon-icons-folder:before {
    content: "\e919";
}
.icon-icons-home:before {
    content: "\e91a";
}
.icon-icons-import:before {
    content: "\e91b";
}
.icon-icons-information:before {
    content: "\e91c";
}
.icon-icons-label:before {
    content: "\e91d";
}
.icon-icons-logout:before {
    content: "\e91e";
}
.icon-icons-maintainancelarge:before {
    content: "\e91f";
}
.icon-icons-maintainance:before {
    content: "\e920";
}
.icon-icons-manifest:before {
    content: "\e921";
}
.icon-icons-market-place:before {
    content: "\e922";
}
.icon-icons-NCR:before {
    content: "\e923";
}
.icon-icons-paper:before {
    content: "\e924";
}
.icon-icons-PDF:before {
    content: "\e925";
}
.icon-icons-POD:before {
    content: "\e926";
}
.icon-icons-print:before {
    content: "\e927";
}
.icon-icons-process:before {
    content: "\e928";
}
.icon-icons-refresh:before {
    content: "\e929";
}
.icon-icons-release:before {
    content: "\e92a";
}
.icon-icons-reporting:before {
    content: "\e92b";
}
.icon-icons-save:before {
    content: "\e92c";
}
.icon-icons-Search:before {
    content: "\e92d";
}
.icon-icons-setting:before {
    content: "\e92e";
}
.icon-icons-tickbox:before {
    content: "\e92f";
}
.icon-icons-tick:before {
    content: "\e930";
}
.icon-icons-time:before {
    content: "\e931";
}
.icon-icons-unarchive:before {
    content: "\e932";
}
.icon-icons-upload:before {
    content: "\e933";
}
.icon-icons-user-configurationlarge:before {
    content: "\e934";
}
.icon-icons-user-managementlarge:before {
    content: "\e936";
}
.icon-icons-user:before {
    content: "\e938";
}
.icon-icons-64:before {
    content: "\e939";
}

