.select2-container--default .select2-selection--multiple {
  border: 1px solid #e0e0e0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.v-select .vs__dropdown-toggle {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  width: 100%;
  height: 34px;
  font-size: 14px;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e0e0e0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.has-error .v-select .vs__dropdown-toggle {
  border: 1px solid $brand-error;
}