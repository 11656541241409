﻿@import './variables';

.site-header {
    background: #E5E5E5;
    padding: 1.25em 0;
    position: fixed;
}

.left-submenu {
    margin-left: 7px;
}

.left-menu {
    background-color: #203440;
}

.left-menu a {
    color: #E5E5E5;
}

.left-menu a:hover {
    background-color: #43B54A !important;
    color: #E5E5E5;
}

.sub-menu li a {
    color: #203440;
}

.sub-menu .current-menu-item > a,
.sub-menu .current-menu-ancestor > a,
.sub-menu .current_page_item > a,
.sub-menu .current_page_ancestor > a {
    color: #000;
}

.sub-menu li a:hover {
    color: #fff !important;
    background-color: #43B54A;
    text-decoration: none !important;
}

.sub-menu {
    z-index: 10;
    max-height: 0;
    overflow: hidden;
    text-align: left;
    position: absolute;
    /*background-color: #d2e7f1;*/
    background: linear-gradient(#fff, #E5E5E5); /* Standard syntax */
    background: -webkit-linear-gradient(#fff, #E5E5E5); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(#fff, #E5E5E5); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(#fff, #E5E5E5); /* For Firefox 3.6 to 15 */
    left: 0;
    top: 25px;
    opacity: 0.9;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=90);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#logoInfo {
    padding-top: 5px;
    font-size: 12px;
    color: #43B54A;
    width: 20% !important;
}

.btn-green {
    background: #43B54A;
    position: relative;
    z-index: 0;
    border: none;
    box-shadow: none;
    color: #fff;
}

.btn-green:hover {
    text-decoration: none !important;
    background: #43B54A;
    color: #fff;
}

.highlight {
    color: #43B54A !important;
}

table {
    background-color: white;
}

.table thead tr th {
    /*color:#3F6F9F !important;*/
    background-color: transparent !important;
    font-weight: bold;
    white-space: nowrap;
}

/*input:not([readonly])[required],textarea:not([readonly])[required] {
    border-color: #43B54A !important;
}*/

.footer-content {
    background-color: #E5E5E5;color: #203440;
}

.main-navigation .extendedSearch {
    position: absolute;
    top: 5px;
    right: 380px;
}

/******************************************************************  TABLE *******************************************************/

.table-bordered {
    border: 1px solid #E5E5E5;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    padding: 4px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #E5E5E5;
}

.table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #E5E5E5;
}

.table > caption + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
    border-top: 0;
}

.table > tbody + tbody {
    border-top: 2px solid #E5E5E5;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
    border: 1px solid #E5E5E5;
}

.pagination > li > a,
.pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #43B54A;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #E5E5E5;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    color: #43B54A;
    background-color: #eee;
    border-color: #E5E5E5;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    z-index: 1;
    color: #fff;
    cursor: default;
    background-color: grey;
    border-color: grey;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
    color: #999;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #E5E5E5;
}

.inline-edit td, .inline-edit th {
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 3px;
    font-size: 13px;
}

.inline-edit th {
    font-weight: bold;
    border-bottom: 2px solid #E5E5E5;
    vertical-align: top;
}

.inline-edit td input {
    padding: 2px;
    font-size: 13px;
}

/************************************************************/

*,
div,
input.form-control {
    border-radius: 0;
}


.fixed {
    position: fixed;
    top: 0;
}

/* SEARCH */

.input-icon-wrapper {
    position: relative;
}

.input-icon-wrapper input {
    padding-right: 2em;
}

.input-icon-wrapper .input-icon {
    position: absolute;
    top: 0.61em;
    right: 7px;
    color: #BBB;
    font-size: 1.2em;
}

.input-icon-wrapper [class^="icon-"].input-icon {
    top: 0.3em;
    font-size: 1.7em;
}

/* TABLE TOTALS */

.table-totals {
    color: #43B54A;
    padding: 0 2em;
    display: block;
    font-size: 1.0em;
}

.table-totals span {
    font-weight: bold;
    padding-left: 0.9em;
}

body {
    background: #eeeded;
}

/* Material shadow*/

.card-1,
.panel-enodo {
    //box-shadow: 0 1px 1px rgba(100, 100, 100, 0.12), 0 1px 1px rgba(100, 100, 100, 0.24);
    transition: all 0.2s ease-in-out;
}
.panel.panel-enodo {
    margin-bottom: .75em;
}

.panel-enodo.selected {
    box-shadow: 0 5px 7px rgba(70, 70, 70, 0.19), 0 6px 6px rgba(70, 70, 70, 0.23);
}

.card-2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.card-3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.card-4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card-5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
} 

/* Helper Classes */

.flex-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}

.flex-wrapper > .flex-item {
    margin: 0.5em;
}

.bolderFont {
    font-weight: bold;
}

/* Pages */

.center {
    text-align: center;
}

/* Cursor */

html.processing {
    cursor: progress;
}

.toggle-icon {
    padding: 0 0 0 1em;
    font-size: 1.25em;
}


.field-validation-error {
    color: #a94442;
}


.dx-toolbar-item {
    display: inline-block;
}

.dx-toolbar-items-container {
    position: inherit;
}

.dx-toolbar-items-container .btn {
    display: inline-block;
    margin-bottom: 10px;
    color: black;
    border-radius: 4px;
    box-shadow: 0 1px 3px transparent;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
    border-color: #ddd;
    color: #333;
    min-height: 36px;
    line-height: 28px;
}

.helper-text{

    line-height: 1.3em;
    color: #AAA;
}

.validation-summary-errors{
    color: #F95759;
    
    ul{
        list-style: none;
        padding: 0;
    }
}

// Select2
select.error + .select2.select2-container .select2-selection{
    border-color: red;
}

// Status
// The CSS for the colored dot that represents your current status
$red:#e74c3c;
$orange:#e67e22;
$yellow:#f1c40f;
$green:#2ecc71;

.system-status{
    font-weight: normal;
}
.status-dot {
  border-radius: 5px;
  border: 1px solid #AAA;
  display:inline-block;
  width:10px;
  height:10px;
  margin-right:5px;
  &.critical {
    background-color:$red;
  }
  &.major {
    background-color:$orange;
  }
  &.minor {
    background-color:$yellow;
  }
  &.none {
    background-color:$green;
  }
}

// SD-30549 - I know it'a a hack. But even with jqueryui v1.12.1, I see the problem 
.ui-menu .ui-state-focus{
    margin: 0px!important;
}

.privacy-statement {
    width: 980px;
    height: 95px;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: center;
    color: #5d7b8a;
}

.no-padding {
    padding: 0px !important;
}