﻿.ui-widget-header {
    background: #43B54A;
    border: none;
    color: white;
}

.ui-state-default,
.ui-widget-content .ui-state-default {
    color: #777777;
    border: 1px solid #d0d0d0;
}

.ui-dialog {
    padding: 0;
}

    .ui-dialog .ui-dialog-buttonpane {
        border: none;
    }

        .ui-dialog .ui-dialog-buttonpane button {
            background-color: #43B54A;
            color: #FFF;
            border: none;
        }

/*
    Custom JsTree
*/
.jstree-default .jstree-clicked {
    background: white;
    border: none;
    box-shadow: none;
}

.ui-autocomplete.ui-front.ui-menu.ui-widget.ui-widget-content {
    z-index: 10000;
}

.ui-widget .custom-combobox {
    position: relative;
    display: inline-block;
    padding: 0 0.25em;
    width: 15.5em;
}

.ui-widget .custom-combobox-toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-left: -1px;
    padding: 0.5em;
    background-image: linear-gradient(to bottom, #43B54A 0%, #43B54A 100%);
    color: white;
}

.ui-widget .custom-combobox-input {
    margin: 0;
    padding: 5px 10px;
    font-size: 0.8em;
    width: 16em;
}

.ui-menu .ui-menu-item {
    font-size: 0.8em;
}
.ui-menu .ui-menu-item:hover {
    font-size: 0.8em;
    font-weight: normal;
    
}

*,
*:after,
*:before {
	box-sizing: border-box;
}

.ui-autocomplete {
    max-height: 500px;
    overflow-y: auto;
    /* prevent horizontal scrollbar */
    overflow-x: hidden;
    background: white !important;
  }

 .ui-menu .ui-menu-item.ui-state-focus {
    text-overflow: ellipsis;
    overflow-y: scroll;
    overflow-x: hidden !important;
}

.ui-datepicker .ui-datepicker-header{
    background: white !important;
    color: #777;        
}

.ui-datepicker .ui-datepicker-next, .ui-datepicker .ui-datepicker-prev {
    border-color: white;
    background: white !important;
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
    background: 50% top repeat #357ebd;
    color: white !important;
    border-color: white !important;    
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
    border: 1px solid transparent !important;
    padding: 9px 7px 9px 5px !important;
    border-radius: 3px;
}

.ui-widget-content {
    border: 1px solid #ccc;
    background-color: #fff;    
}

.ui-widget-header .ui-icon {
    background-image: url("../css/vendor/images/ui-icons_222222_256x240.png");
}
