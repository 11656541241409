@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon.eot?8r5xj1');
	src:url('../fonts/icomoon.eot?#iefix8r5xj1') format('embedded-opentype'),
		url('../fonts/icomoon.woff?8r5xj1') format('woff'),
		url('../fonts/icomoon.ttf?8r5xj1') format('truetype'),
		url('../fonts/icomoon.svg?8r5xj1#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-sigma:before {
	content: "\e603";
}
