﻿@import "../variables";

.basic {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;

    &__content {
        color: $font-color-basic;
        max-width: 400px;
    }

    &__title {
        color: white;
        font-size: 100px;
        margin: 0 auto;
        max-width: 250px;
        margin-bottom: 0.5em;
    }

    &__text {
        font-size: 22px;
        overflow: hidden;
        border-right: 3px solid;
        white-space: nowrap;
        width: 270px;
        animation: type 2s steps(21), blink .75s step-end infinite;
        margin: 0 auto;
        font-weight: normal;
        animation-fill-mode: both;
    }
}
