﻿/*
    Boostrap datatables custom CSS
*/
.dataTable {
    font-size: 0.90em;
}

div.dataTables_wrapper div.dataTables_filter label{
    overflow: hidden;
}

.dataTable [class^="icon-"], .dataTable [class*=" icon-"] {
    font-size: 1.5em;
}

.dataTable thead tr th{
    vertical-align: middle;
}

table.dataTable.table-condensed .sorting:after, table.dataTable.table-condensed .sorting_asc:after, table.dataTable.table-condensed .sorting_desc:after {
    top: inherit;
}

.dataTable .full,
.dataTable.input-table input[type="text"] {
    width: 100%;
}

.dataTable.input-table th {
    padding: 0.25em;
}

.dataTable.input-table button.btn {
    padding: 0.15em 0.5em;
}

.dataTable.input-table td.error {
    display: table-cell;
}

.dataTable.input-table td.error:before {
    font-family: "FontAwesome";
    content: "\f071";
    color: red;
    float: right;
    margin-right: 0.5em;
}

.dataTable thead > tr > th {
    white-space: normal;
}

.dataTable tfoot {
    display: table-footer-group;
}

.dataTable tfoot > tr > th {
    font-weight: bold;
}

/* Group */
table tbody > tr > td.expanded-group,
table tbody > tr > td.collapsed-group {
    background-color: #F9F9F9;
    border-bottom: 1px solid #B2B2B2;
    font-weight: bold;
}

/* BUTTON EXT*/
.table-button-panel {
    margin: 0.5em 0;
}

.table-button-panel:before {
    content: "";
}

.table-button-panel {
    a, button {
        margin: 0 1em;
        padding: 0.5em 1.5em;
        font-size: 0.85em;
        font-weight: bold;
        background-color: #EEE;
    }

    a:last-child,
    button:last-child{
        margin-right: 0;
    }
} 

