.consignment-detail__pallet-count {
  display: flex;
}

.consignment-detail__pallet-count__pallet {
  flex: 1;
  display: flex;
  align-items: center;
}

.consignment-detail__pallet-count__pallet:not(:last-child) {
  margin-right: 1em;
}

.consignment-detail__pallet-count__pallet__code {
  overflow: hidden;
  flex: 1;
  margin-right: 0.5em;
}

.consignment-detail__pallet-count__pallet__qty {
  flex: 2;
}

.tooltip-icon {
  font-size: 1.3em;
  line-height: 1.3em;
  vertical-align: middle;
}

.create-consignment-header { 
  display: flex;
  align-items: center;
  
  & > * {
    margin-bottom: 0;
  }

  > .end {
    margin-left: auto;
  }
}