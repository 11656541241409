@import './fonts';
@import "./variables";

// vendors
$bootstrap-sass-asset-helper: false;
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
@import "~bootstrap-sass/assets/stylesheets/bootstrap";

$fa-font-path: "~font-awesome/fonts/";
@import "~font-awesome/scss/font-awesome.scss";


// Helpers
@import './global';
@import "./custom";
@import "./sidemenu";
@import "./bootstrap";
@import "./jquery";
@import "./datatables";
@import "./select2";
@import "./helpers";

// Animate
.slideInLeft {
    animation-duration: 0.65s;
}

// Views
@import "./createConsignments";
@import "./bookingHistory";

// Main
body.dark{
    background-color: $background-color;
}

.content-wrapper::before{
    background-image: url(../../../Content/images/OneFlo_1000x545.png);
    background-size: 70%;
    background-repeat: no-repeat;
    content: "";
    position: fixed;
    background-position: center; 
    width: -webkit-calc(100% - 230px);
    width:    -moz-calc(100% - 230px);
    width:         calc(100% - 230px);
    height: 100%;
    opacity: .03;
    top: 0;
    left: $sidebar-width;
    padding-left: $sidebar-width;
    z-index: -1;
    overflow: hidden;
}

// App
@import"./app/progress";
@import"./app/basic";
@import"./app/error";

.reports {
    padding: 43px 0 78px 0 !important;
}
.reports .box > .icon {
    text-align: center;
    position: relative;
}

.reports .box > .icon > .image {
    position: relative;
    z-index: 2;
    margin: auto;
    width: 88px;
    height: 88px;
    border: 8px solid white;
    line-height: 88px;
    border-radius: 50%;
    background: #63B76C;
    vertical-align: middle;
}

.reports .box > .icon:hover > .image {
    background: #333;
}

.reports .box > .icon > .image > i {
    font-size: 36px !important;
    color: #fff !important;
}

.reports .box > .icon:hover > .image > i {
    color: white !important;
}

.reports .box > .icon > .info {
    margin-top: -24px;
    background: rgba(0, 0, 0, 0.04);
    border: 1px solid #e0e0e0;
    padding: 30px 0 10px 0;
}

.reports .box > .icon:hover > .info {
    background: rgba(0, 0, 0, 0.04);
    border-color: #e0e0e0;
    color: white;
}

.reports .box > .icon > .info > h3.title {
    font-family: "Roboto",sans-serif !important;
    font-size: 16px;
    color: #222;
    font-weight: 500;
}

.reports .box > .icon > .info > p {
    font-family: "Roboto",sans-serif !important;
    font-size: 13px;
    color: #666;
    line-height: 1.5em;
    margin: 20px;
}

.reports .box > .icon:hover > .info > h3.title, .box > .icon:hover > .info > p, .box > .icon:hover > .info > .more > a {
    color: #222;
}

.box > .icon > .info > .more a {
    font-family: "Roboto",sans-serif !important;
    font-size: 12px;
    color: #222;
    line-height: 12px;
    text-transform: uppercase;
    text-decoration: none;
}

.box > .icon:hover > .info > .more > a {
    color: #fff;
    padding: 6px 8px;
    background-color: #63B76C;
}

.box .space {
    height: 30px;
}