﻿$primary-color: #00E676;
$primary-color--dark: darken($primary-color, 5%);

$brand-danger: #ce4844 !default;
$brand-error: #a94442;

$secondary-color: #333;

$background-color: #283237;
$background-color--light: #D0D0D0;

$main-font: 'Open Sans';
$title-font: Montserrat;
$font-color-basic: white;

$brand-primary: $primary-color !default; // #337ab7

$body-color-light: #676767;

$sidebar-width: 210px;