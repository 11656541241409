﻿@import "../variables";

.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;

    &__logo{
        width: 50%;
    }

    &__content {
        color: $secondary-color;
        max-width: 800px;
        text-align:center;
    
        .--margin-404{
            margin-left: 7em;
        }

        .--margin-500{
            margin-left: -1em;
        }
    }

    &__text__main {
        font-size: 22px;
        overflow: visible;        
        white-space: nowrap;        
        animation: type 2s steps(21), blink .75s step-end infinite;
        margin: 0 auto;
        font-weight: bold;
        animation-fill-mode: both;
        text-align: center;
        font-family:$title-font;
    }

    &__text {
        font-size: 18px;
        overflow: visible;        
        white-space: nowrap;        
        animation: type 2s steps(21), blink .75s step-end infinite;
        margin: 0 auto;
        font-weight: normal;
        animation-fill-mode: both;
        text-align: center;
        font-family:$main-font;
    }
}
